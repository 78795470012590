const modules = vueTools.importModule('modules', 'tools', 'custom').default;

 modules.settingsMenu.endpoints.push('bookingsetting')

// Módulo Opciones
modules.settingsMenu.endpoints.push('eventfair')

if (modules.adminMenu) {
    modules.adminMenu.endpoints.push({endpoint: 'report', label: 'Impuestos por file', componentName: 'BookingTaxReport', report: 'bookingtax'});
}

modules.claim = {
    label: 'Reclamos',
    user_access: 'module_claim',
    items_access: 'module_claim_items',
    value: true,
    def: true,
    endpoints: [
        'claim',
        {endpoint: 'report', label: 'Listado de Reclamos', componentName: 'ClaimListReport', report: 'claimlistreport'},
    ],
}

export default modules;
