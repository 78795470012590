<script>
let template = `
    <div class="card no-border-top p-3 mb-0">
        <div v-if="!processingReceipts">
          <div class="form-row col-md-12">
              <div class="form-group col mb-1">
                <label>{{tr('Payment Type')}}</label>
              </div>
              <div class="form-group col mb-1">
                <label>{{tr('Payment Date')}}</label>
              </div>
              <div class="form-group col mb-1 text-right">
                <label>{{tr('Amount')}}</label>
              </div>
              <div class="form-group col mb-1" style="min-width: 80px;">
                <label>{{tr('Status')}}</label>
              </div>
              <div class="form-group col mb-1 text-right" v-if="adminActions">
                <label>{{tr('Receipt')}}</label>
              </div>
              <div class="form-group col mb-1 text-right" v-if="adminActions">
                <label>{{tr('Rate')}}</label>
              </div>
              <div class="form-group col mb-1" v-if="adminActions">
                <label>{{tr('Received Date')}}</label>
              </div>
              <div class="form-group col-2 mb-1">
              </div>
              <div class="form-group col mb-1">
              </div>
          </div>
          <div class="form-row col-md-12" v-for="(row, rowNr) of receipts" :class="getBGClass(rowNr)">
              <div class="form-group col mb-1">
                <label>{{row.PaymentType.Name}}</label>
              </div>
              <div class="form-group col mb-1">
                <label v-if="row.PaymentDate">{{getDate(row.PaymentDate)}}</label>
              </div>
              <div class="form-group col mb-1 text-right">
                <label>{{row.Amount | formatNumber({c: row.Currency})}} {{row.CurrencyId}}</label>
              </div>
              <div class="form-group col mb-1">
                <label>{{tr(row.PaymentStatus)}}</label>
              </div>
              <div class="form-group col mb-1 text-right" v-if="adminActions">
                <label>{{row.ReceivedAmount | formatNumber({c: row.ReceivedCurrency})}} {{row.ReceivedCurrencyId}}</label>
              </div>
              <div class="form-group col mb-1 text-right" v-if="adminActions">
                <label>{{row.CurrencyRate}}</label>
              </div>
              <div class="form-group col mb-1" v-if="adminActions">
                <label v-if="row.ReceivedPaymentDate">{{getDate(row.ReceivedPaymentDate)}}</label>
              </div>
              <div class="form-group col-2 mb-1">
                <label>{{row.Comment}}</label>
              </div>
              <div class="form-group col mb-1 text-right" v-if="canEdit(row)">
                 <i @click="editReceipt(row.id)" class="cursor-pointer">
                      <font-awesome-icon icon="edit"/>
                 </i>
                 <i @click="openInvoice(row.id)" v-if="row.id" class="cursor-pointer ml-3">
                      <font-awesome-icon icon="receipt"/>
                 </i>
              </div>
          </div>
          <div class="form-row col-md-12" v-if="canAdd">
             <i class="add-item cursor-pointer" @click="addNew">
                <font-awesome-icon icon="plus-circle"/>
             </i>
          </div>
          <br>
          <div class="form-row">
                <div class="form-group col-md-2">
                    <label>{{tr('_TOTAL')}}:</label>
                </div>
                <div class="form-group col-md-2 text-right">
                    <label>{{totalReceipts | formatNumber({c: recordStore.PriceCurrencyId, d: 2})}} {{recordStore.PriceCurrencyId}}</label>
                </div>
           </div>

        </div>
        <div v-else>
            <img class="loading-gif" src="@/img/loading.gif">
        </div>
    </div>
`;

import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'booking-receipts',
    template: template,
    data () {
        return {
            receipts: api.receipts,
            processingReceipts: false,
        }
    },
    mounted: async function () {
        await this.setReceipts();
        EventBus.$on('close-receipt', this.setReceipts);
        EventBus.$on('after-save', this.receiptSaved);
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            adminActions: state => state.main.adminActions,
        }),
        canAdd () {
          return true;
        },
        totalReceipts () {
            let res = 0;
            for (let row of this.receipts) {
                if (row.PaymentStatus == 'CANCELED') continue;
                res += travelTools.currencyConvert(row.CurrencyId, this.recordStore.PriceCurrencyId,
                    parseFloat(row.Amount), this.recordStore.CurrencyRates);
            }
            return res;
        },
        receiptCurrency () {
            let res = {};
            for (let r of this.receipts) {
                if (!res[r.CurrencyId]) res[r.CurrencyId] = r.CurrencyId;
            }
            if (Object.keys(res).length==1) {
                return Object.keys(res)[0];
            }
        },
    },
    methods: {
        async setReceipts () {
            this.processingReceipts = true;
            await api.setReceipts(this.recordStore.id);
            this.receipts = api.receipts;
            this.processingReceipts = false;
        },
        getDate (d){
            return tools.getDate(d);
        },
        async editReceipt (id){
            EventBus.$emit('open-modal-record', {modalRecord: null, modalTable: 'bookingreceipt',
                modalId: id, closeAction: 'close-receipt'});
        },
        async addNew () {
            let model = await api.importMixinModule('bookingreceipt', 'model');
            let newReceipt = await new model({tableName: 'bookingreceipt'});
            newReceipt.BookingId = this.recordStore.id;
            EventBus.$emit('open-modal-record', {modalRecord: newReceipt, modalTable: 'bookingreceipt',
                modalId: 'new', closeAction: 'close-receipt'});
        },
        openInvoice (paymentId) {
            let route = this.$router.resolve({
                name: 'booking-invoice',
                params: {
                    hash: this.recordStore.Hash,
                    id: this.recordStore.id,
                    payment: paymentId
                }
            })
            window.open(route.href, '_blank');
        },
        getBGClass (idx) {
            if (idx % 2 == 0) return 'bg-silver';
            return '';
        },
        canEdit (row) {
            return true;
        },
        receiptSaved (value, table) {
        }
    },
    beforeDestroy() {
        EventBus.$off('close-receipt', this.setReceipts);
        EventBus.$off('after-save', this.receiptSaved);
    },
}
</script>

