<template>
  <div
    class="modal fade printable"
    id="locationDataModal"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-medium" role="document">
      <div class="modal-content">
        <div
          class="modal-body booking-form-print"
          id="print-content"
        >
          <div v-if="ready">
              <div v-for="(data, key) in apiData" :key="key">
                <span class="font-weight-bold">{{ tr(key) }}: </span> {{ data }}
              </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">
            {{ tr("Close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "location-data-modal",
  props: ["show", "data"],
  mounted() {
    this.getData();
    $("#locationDataModal").modal(
      { backdrop: "static", keyboard: false },
      "show"
    );
    this.ready = true;
  },
  data() {
    return {
      ready: false,
      apiData: {},
    };
  },
  methods: {
    close() {
      this.$emit("update:show", false);
      $("#locationDataModal").modal("hide");
    },
    async getData() {
      let data = {};
      try {
        data = await api.get(`/api/find_ip/${this.data.RemoteAddr}`);
        if (data) {
          this.apiData = data;
          this.ready = true; // Marcar como listo cuando los datos se cargan
        } else {
          alert("Hubo un error al obtener los datos");
          this.close();
        }
      } catch (error) {
        alert("Hubo un error al obtener los datos");
        this.close();
      }
    },
  },
};
</script>
