<template>
    <div class="col-md-3 mt-2 d-flex flex-column pl-3">
        <booking-quote-config-checkbox></booking-quote-config-checkbox>
        <div class="form-check checkbox-group">
            <input v-model="record.DisableTerms" 
            class="form-check-input" 
            type="checkbox"
            @change="change('DisableTerms')" 
            id="DisableTerms">
            <label for="DisableTerms" class="smaller form-check-label">{{'Deshabilitar Terminos y Condiciones'}}</label>
        </div>
        <div class="form-check checkbox-group">
            <input v-model="record.ShowAgencyCommission" 
            class="form-check-input" 
            type="checkbox"
            @change="change('ShowAgencyCommission')" 
            id="ShowAgencyCommission">
            <label for="ShowAgencyCommission" class="smaller form-check-label">{{'Mostrar Comisión de Agencia en Breakdown'}}</label>
        </div>
        <div class="form-check checkbox-group">
            <input v-model="record.ShowInvoiceAgencyCommission" 
            class="form-check-input" 
            type="checkbox"
            @change="change('ShowInvoiceAgencyCommission')" 
            id="ShowInvoiceAgencyCommission">
            <label for="ShowInvoiceAgencyCommission" class="smaller form-check-label">{{'Mostrar Comisión de Agencia en Factura'}}</label>
        </div>
    </div>
</template>

<script>
const bookingQuoteConfigCheckbox = importVueComp('components/booking', 'BookingQuoteConfigCheckbox', 'custom');
export default {
    name: 'custom-booking-quote-config-checkbox',
    mixins: [bookingQuoteConfigCheckbox],
    components: {
        'booking-quote-config-checkbox': bookingQuoteConfigCheckbox,
    },
}
</script>
