<script>
const bookingNavItems = importVueComp('components/booking', 'BookingNavItems', 'custom');
export default {
  name: 'custom-booking-nav-items',
  extends: bookingNavItems,
  methods: {
    getItems () {
      this.itemsList = this.getItemList();
      let expensesItem = _.find(this.itemsList, (r) => r.id == 'expenses');
      if (expensesItem) {
        expensesItem.vIf = () => {return !this.isTemplate};
      }
      this.setItems();
      this.itemsList.push({
        id: 'bookingClaim',
        label: 'Claims',
        vIf: () => { return this.bookingConfirmed },
        showTabs: () => { return this.showTabs }
      });
    }
  }
}
</script>
