<template>
    <!-- Modal window -->
    <div class="modal fade bd-example-modal-lg" 
        id="service-selector-modal" 
        tabindex="-1" 
        role="dialog"
        data-backdrop="static" 
        aria-labelledby="service-selector-modal" 
        aria-hidden="true" 
        ref="modal"
    >
        <div class="modal-dialog modal-dialog-centered modal-lg modal-large" 
            role="document"
        >
            <!-- Modal content -->
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <!-- Modal Header Title -->
                    <div class="col-6">
                        <div class="form-row">
                            <div class="form-group col-md-12 section-title mb-0">
                                <!-- Title if Template -->
                                <label v-if="showTemplates" class="ml-4">
                                    {{tr('Add Template from day')}} {{dayNr+1}}
                                </label>
                                <!-- Title if Service -->
                                <label class="ml-4" v-else>
                                    {{tr('Add Service on day')}} {{dayNr+1}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- Modal Header Buttons -->
                    <div class="col-6 d-flex text-right">
                        <!-- Service Button -->
                        <button 
                            type="button" 
                            class="btn mx-2" 
                            :class="getButtonClass(false)"
                            @click="showTemplates = !showTemplates"
                        >
                            {{ tr('Services') }}
                        </button>
                        <!-- Template Button -->
                        <button 
                            type="button" 
                            class="btn mx-2" 
                            :class="getButtonClass(true)"
                            @click="showTemplates = !showTemplates"
                        >
                            {{ tr('Templates') }}
                        </button>
                        <!-- Close Button -->
                        <button 
                            type="button" 
                            class="close" 
                            data-dismiss="modal" 
                            aria-label="Close"  
                            @click="close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <!-- Modal Body -->
                <div class="modal-body">
                    <div class="row w-100">
                        <div class="col-12 d-flex justify-content-center">
                            <!-- Service Selector Component -->
                            <service-selector
                                v-if="!showTemplates"
                                :dayNr="dayNr"
                                :language="language"
                                :city="city"
                                :modal="true"
                                @add="addServices"
                                @close="close"
                            >
                            </service-selector>
                            <!-- Template Selector Component -->
                            <template-selector
                                v-if="showTemplates"
                                @add="addTemplate"
                                @close="close"
                                :language="language"
                                :dayNr="nextDayNr"
                            >
                            </template-selector>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    const serviceSelector = importVueComp('components/service', 'ServiceSelector');
    const templateSelector = importVueComp('components/service', 'TemplateSelector');
    const escape = importVueComp('components/tools', 'Escape');
    export default {
        name: 'service-selector-modal',
        mixins: [escape],
        props: {
            dayNr: {
                type: Number,
                required: true
            },
            language: {
                type: String,
                required: true
            },
            city: {
                type: Number,
                required: false,
                default: null
            }
        },
        components: {
            'service-selector': serviceSelector,
            'template-selector': templateSelector
        },
        data () {
            return {
                showTemplates: false,
                modalInstance: null
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initializeModal();
            });
        },
        methods: {
            initializeModal() {
                if (this.$refs.modal) {
                    try {
                        this.modalInstance = $(this.$refs.modal).modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                        this.modalInstance.modal('show');
                    } catch (error) {
                        console.error('Error initializing modal:', error);
                    }
                }
            },
            hideModal() {
                if (this.modalInstance) {
                    try {
                        this.modalInstance.modal('hide');
                    } catch (error) {
                        console.error('Error hiding modal:', error);
                    }
                }
            },
            addServices (records){
                this.$emit('addServices', records);
                this.hideModal();
            },
            addTemplate (records, insertDays){
                this.$emit('addTemplate', records[0], insertDays)
                this.hideModal();
            },
            getButtonClass (f) {
                if (f==this.showTemplates) return 'btn-primary';
                return 'btn-secondary';
            },
            close () {
                this.hideModal();
                this.$emit('close');
            },
            // Other methods...
        },
        computed: {
            nextDayNr() {
                return this.dayNr + 1;
            }
        },
        beforeDestroy() {
            this.hideModal();
            // Ensure any other cleanup is done here
            this.modalInstance = null;
        },
        errorCaptured(err, vm, info) {
            console.error('Error captured in ServiceSelectorModal:', err, info);
            // Handle the error appropriately
            // Return false to prevent the error from propagating further
            return false;
        }
    }
</script>
