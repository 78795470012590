<template>
    <report-window endpoint="/custom/reports/claimlistreport"
      v-if="ready"
      :fields="fields"
      :title="cardTitle || title"
      :headers="headers"
      :templates="templates"
      :perPage="(options && options.perPage)? options.perPage: 500"
      :hideFilters="options && options.hideFilters"
      :runMounted="options && options.runMounted"
      :cardView="options && options.cardView"
      :columnClick="columnClick"
      :notFilterable="options && options.notFilterable"
      :headerColumns="headerColumns"
      :current.sync="current"
      :columnsClasses="columnsClasses"
    ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
  name: 'claim-list-report',
  props: ['options', 'cardTitle'],
  components: {
      ReportWindow,
  },
  computed: {
      ...mapState({
          managerActions: state => state.main.managerActions,
          user: state => state.main.user,
      }),
  },
  mounted () {
  frontTools.setReportValues(this);
      this.fields = Object.assign([], this.fields);
      this.ready = true;
  },
  data () {
      let self = this;
      let bookingView = api.importMixinModule('booking', 'views');
      let bookingDayServiceView = api.importMixinModule('bookingdayservice', 'views');
      let bookingStatusOptions = bookingView.bookingStatusOptions;
      bookingStatusOptions.push({value: 'ALL_CONFIRMED', label: 'All Confirmed'});
      return {
          current: {
              BookingStatus : 'ALL_CONFIRMED',
          },
          ready: false,
          fields: [
            {name: 'FromDate', editor: 'date', label: 'From', required: true},
            {name: 'ToDate', editor: 'date', label: 'To', required: true},
            {name: 'BookingId', editor: 'number', decimales: 0, label: 'File'},
            {name: 'SalesOriginId', relation: 'salesorigin', optionLabels: 'Name'
                , editor: 'vue-select', label: 'Sales Origin', addBlank: true
            },
            {name: 'SalesUserId', relation: 'user', optionLabels: 'UserName', editor: 'vue-select', label: 'Seller',
                addBlank: true, filters: (row) => {
                    if (row.Closed) return false;
                    if (!row.Department) return false;
                    if (!row.Department.Sales) return false;
                    return true;
                }
            },
            {name: 'OperUserId', relation: 'user', optionLabels: 'UserName', editor: 'vue-select',
                label: 'Operadora', addBlank: true,
                filters: (row) => {
                    if (row.Closed) return false;
                    if (!row.Department) return false;
                    if (!row.Department.Oper) return false;
                    return true;
                }
            },
            {name: 'SupplierId', relation: 'supplier', optionLabels: 'Name', label: 'Supplier',
                addBlank: true, editor: 'vue-select',
                requiredCallback: this.checkDates
            },
            {name: 'CityId', relation: 'city', optionLabels: 'Name'
                , editor: 'vue-select', label: 'City', addBlank: true
            },
            {name: 'CountryId', relation: 'country', optionLabels: 'Name', editor: 'vue-select', label: 'Country',
                addBlank: true, filters: (r) => {return r.CityType}
            },
          ],
          headerColumns: [
              'ClaimId',
              'TransDate',
              'CreatedUser',
              'File',
              'PaxName',
              'SalesOrigin',
              'UserName',
              'OperUserName',
              'SupplierName',
              'Problem',
              'Comment',
          ],
          title: 'Listado de Reclamos',
          headers: {
              TransDate: 'Date',
              UserName: 'Seller',
              OperUserName: 'Operadora',
              PaxName: 'Passenger',
              ServiceName: 'Service',
              BookingStatus: 'File Status',
              Status: 'Service Status',
              SupplierName: 'Supplier',
              Problem: 'Problem',
              ClaimId: 'Claim',
              SalesOrigin: 'Origen',
              CreatedUser: 'Creado Por'
          },
          templates: [
              {name: 'TransDate', callback: (row) => {
                  if (!row.TransDate) return '';
                  return moment(row.TransDate).format("DD/MM/YYYY")
                }
              },
              {name: 'ServiceDate', callback: (row) => {
                  if (!row.ServiceDate) return '';
                  return moment(row.ServiceDate).format("DD/MM/YYYY")
                }
              },
              {
                  name: "Problem",
                  callback: (row) =>  {
                      if (row.calculated) return " ";
                      if (!row.Problem) return " ";
                      let maxLength = 30;
                      if (row.Problem.length > maxLength) {
                          return row.Problem.substring(0, maxLength) + '...';
                      }
                      return row.Problem;
                  }
              },
              {
                  name: "Comment",
                  callback: (row) =>  {
                      if (row.calculated) return " ";
                      if (!row.Comment) return " ";
                      let maxLength = 30;
                      if (row.Comment.length > maxLength) {
                          return row.Comment.substring(0, maxLength) + '...';
                      }
                      return row.Comment;
                  }
              }
          ],
          columnClick: {
              File: this.showBooking,
              ClaimId: this.showClaim,
              Comment: this.showClaim,
              Problem: this.showClaim,
          },
          columnsClasses: {
              ClaimId: 'cursor-pointer-report',
              Comment: 'cursor-pointer-report',
              Problem: 'cursor-pointer-report',
              File: 'cursor-pointer-report',
              TransDate: 'width-50'
          }
      }
  },
  methods: {
      showBooking (row) {
          vueTools.showBooking(this.$router, 'my-booking', {id: row.File.toString(), TemplateType: 'NONE'});
      },
      showClaim (row) {
          this.$root.$children[0].openModalRecord({
              modalRecord: null, modalTable: 'claim', modalId: row.ClaimId,
          });
      },
  }
}
</script>