export default  {
    systemMenu: {
        label: 'System',
        user_access: 'system_menu',
        icon: 'tools',
        value: false,
        def: true,
        endpoints: [
            'department',
            'supplierclass',
            'paymentcondition',
            'currencyconversion',
            'notificationcode',
            'mail',
            //'crontask',
            //'reporttask',
            'paymenttype',
            'language',
            'currency',
            'image',
            'customertype',
            'expensetype',
            'mailinglist',
            //'hotelcost',
            'user',
            'accessgroup',
            //{table: 'accessgroup', endpoint: 'abm-access-group', label: 'Access Groups', path: 'accessgroup'},
            'bookingview',
            {endpoint: 'report', label: 'User Sessions', componentName: 'UserSessionReport', report: 'userasession'},
            {endpoint: 'report', label: 'User Active Sessions', componentName: 'UserActiveSessionReport', report: 'useractivesession'},
            {endpoint: 'report', label: 'System Logs', componentName: 'SystemLogsReport', report: 'systemlogs'},
            {endpoint: 'report', label: 'Register Activity', componentName: 'RegisterActivityReport', report: 'registeractivity'},
        ]
    },
    settingsMenu: {
        label: 'Settings',
        icon: 'cog',
        user_access: 'settings_menu',
        items_access: 'settings_menu_items',
        value: false,
        def: true,
        endpoints: [
            'city',
            'country',
            'station',
            'agency',
            'hotelcategory',
            'serviceclass',
            'currencyrate',
            'includes',
            'salesorigin',
            'supplier',
            'period',
            'termsandconditions',
            'textsummary',
            'roomtype',
            'servicetype',
            'tripmode',
            'service',
        ],
    },
    servicesMenu: {
        label: 'Fares',
        icon: 'tag',
        user_access: 'services_menu',
        value: false,
        def: true,
        endpoints: [
            'hotel',
            'supplierservice',
        ]
    },
    salesMenu: {
        label: 'Sales',
        icon: 'credit-card',
        user_access: 'sales_menu',
        items_access: 'sales_menu_items',
        value: false,
        def: true,
        endpoints: [
            {table: 'booking', endpoint: 'abm-booking', label: 'Quotations'},
            {table: 'booking', endpoint: 'abm-my-booking', label: 'My Quotations', path: 'mybookings'},
            {table: 'booking', endpoint: 'abm-my-templates', label: 'My Templates', path: 'mytemplates'},
            {table: 'booking', endpoint: 'abm-private-templates', label: 'Private Templates', path: 'privatetemplates'},
            {table: 'booking', endpoint: 'abm-templates', label: 'Templates', path: 'templates'},
            {table: 'booking', endpoint: 'abm-packages', label: 'Supplier Packages', path: 'packages'},
            {table: 'booking', endpoint: 'abm-my-sales', label: 'My Sales', path: 'mysales'},
        ]
    },
    operMenu: {
        label: 'Operations',
        icon: 'cogs',
        user_access: 'oper_menu',
        items_access: 'oper_menu_items',
        value: false,
        def: true,
        endpoints: [
            {table: 'booking', endpoint: 'abm-my-files', label: 'My Files', path: 'myfiles'},
            {table: 'booking', endpoint: 'abm-sales', label: 'Confirmed Sales', path: 'sales'},
            'bookingform',
        ]
    },
    reportsMenu: {
        label: 'Reports',
        user_access: 'reports_menu',
        items_access: 'reports_menu_items',
        icon: 'chart-bar',
        value: false,
        def: true,
        endpoints: [
            //{endpoint: 'report', label: 'Booking Views', componentName: 'BookingViewsReport', report: 'bookingsviews'},
            {endpoint: 'report', label: 'User Activity', componentName: 'UserActivityReport', report: 'useractivity'},
            {endpoint: 'report', label: 'Find Files', componentName: 'BookingListReport', report: 'bookinglist'},
            {endpoint: 'report', label: 'Find Passenger', componentName: 'BookingFormListReport', report: 'bookingformlist'},
            {endpoint: 'report', label: 'Dependencies', componentName: 'DependenciesReport', report: 'dependencies'},
            {endpoint: 'report', label: 'User Access', componentName: 'UserAccessReport', report: 'useraccess'},
            {endpoint: 'report', label: 'Hotel Rates', componentName: 'HotelCostListReport', report: 'hotelcost'},
            {endpoint: 'report', label: 'Room Nights', componentName: 'RoomNightsReport', report: 'roomnights'},
            {endpoint: 'report', label: 'Service Rates', componentName: 'ServiceCostListReport', report: 'servicecost'},
            {endpoint: 'report', label: 'Supplier List', componentName: 'SupplierListReport', report: 'supplierlist'},
            {endpoint: 'report', label: 'Service/Hotel List', componentName: 'ServiceListReport', report: 'servicelist'},
            {endpoint: 'report', label: 'Receipts Pending', componentName: 'ReceiptPendingReport', report: 'receiptpending'},
            {endpoint: 'report', label: 'Payments List', componentName: 'OperPaymentListReport', report: 'operpayments'},
            //{endpoint: 'report', label: 'Service by Supplier List', componentName: 'ServiceBySupplierReport', report: 'servicebyprovider'},
            //{endpoint: 'report', label: 'Sales by Seller', componentName: 'SalesByUserReport', report: 'salesbyuser'},
            {endpoint: 'report', label: 'Due Cost', componentName: 'DueCostReport', report: 'duecost'},
            {endpoint: 'report', label: 'Passengers List', componentName: 'PassengersListReport', report: 'passengerslist'},
            {endpoint: 'report', label: 'Oper User Files', componentName: 'FilesOperReport', report: 'filesoper', name: 'filesoper'},
            //{endpoint: 'report', label: 'Service List Quote', componentName: 'ServiceListQuoteReport', report: 'servicelistquote'},
            {endpoint: 'report', label: 'Booked Services', componentName: 'ServiceQtyReport', report: 'serviceqty'},
            {endpoint: 'report', label: 'Agency List', componentName: 'AgencyListReport', report: 'agencylist'},
        ]
    },
    financesMenu: {
        label: 'Finance',
        icon: 'donate',
        user_access: 'finances_menu',
        items_access: 'finances_menu_items',
        value: false,
        def: true,
        endpoints: [
            {endpoint: 'report', label: 'Cash Flow', componentName: 'CashFlowReport', report: 'cashflow', name: 'cashflow'},
            {endpoint: 'report', label: 'Receipts List', componentName: 'ReceiptListReport', report: 'receiptlist'},
            {endpoint: 'report', label: 'Close List', componentName: 'BookingCloseListReport', report: 'closelist'},
            {endpoint: 'report', label: 'Bookings by Seller', componentName: 'BookingsBySalesUserReport', report: 'bookingbysalesman'},
            {endpoint: 'report', label: 'Bookings by Sales Origin', componentName: 'BookingsBySalesOriginReport', report: 'bookingbysalesorigin'},
            {endpoint: 'report', label: 'Bookings by Customer', componentName: 'BookingsByCustomerReport', report: 'bookingbycustomer'},
            {endpoint: 'report', label: 'Sales by Seller', componentName: 'SalesByUserReport', report: 'salesbyuser'},
            {endpoint: 'report', label: 'Sales Summary', componentName: 'SalesSummaryReport', report: 'salessummary'},
            {endpoint: 'report', label: 'Table List', componentName: 'TableListReport', report: 'tablelist'},
        ]
    },
}
