const modules = vueTools.importModule('modules', 'tools', 'crm').default;

modules.CRM = {
    label: 'CRM',
    user_access: 'crm',
    items_access: 'crm_items',
    value: false,
    def: true,
    endpoints: [
      'campaign',
      'paxlead',
      'market',
      'leadvalue',
      'leadstatus',
      'leadtype',
      'leadbudget',
      'survey',
      'subcampaign',
      'surveyquestion',
      'surveypax',
      {endpoint: 'report', label: 'Pax Leads Report', componentName: 'PaxLeadReport', report: 'paxleadcrm'},
      {endpoint: 'report', label: 'Pax Leads Summary', componentName: 'PaxLeadSummaryReport', report: 'paxleadsummary'},
      {endpoint: 'report', label: 'Reporte de Conversión', componentName: 'PaxLeadConversionReport', report: 'paxleadconversion'},
      {endpoint: 'report', label: 'Lead Reviews', componentName: 'LeadReviewsReport', report: 'leadereview'},
    ],
}


export default modules;
