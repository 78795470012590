<template>
    <div class="card no-border-top">
        <abm v-if="ready"
        endpoint="/api/claim/"
        :options="options"
        tableName="claim">
        </abm>
    </div>
</template>


<script>
import abm from '@/components/tools/abm';
import { mapState } from "vuex";
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'bookingClaim',
    components: {
        abm,
    },
    data () {
        let self = this;
        return {
            options: null,
            ready: false,
        };
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
        }),
    },
    async mounted () {
        let options = await api.getModuleFieldsOptions('claim');
        this.options = _.cloneDeep(options);
        this.options.filters = {'BookingId': this.recordStore.id};
        this.options.serverSide = false;
        this.options.showRecord = async (id) => {
            await this.$nextTick();
            this.$root.$children[0].openModalRecord({modalRecord: null, modalTable: 'claim', modalId: id, closeAction: 'close-claim'});
        }
        this.ready = true;
        EventBus.$on('close-claim', this.updateList);
    },
    methods: {
        updateList () {
            this.ready = false;
            setTimeout(() => {this.ready = true}, 1)
        }
    },
    beforeDestroy() {
        EventBus.$off('close-claim', this.updateList);
    },
};
</script>
