<template>
    <transition name="fade" mode="out-in">
        <div class="scroll-up d-flex align-items-center justify-content-center p-2" @click="up" v-if="show">
            <font-awesome-icon icon="arrow-up" size="lg"/>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'scroll-up',
  data () {
    return {
      show: false,
      interval: null
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      if (!document) return;
      if (!document.documentElement) return;
      if (!document.body) return;
      let s = document.documentElement.scrollTop || document.body.scrollTop;
      if (s && s>50) {
        this.show = true
      } else {
        this.show = false;
      }
    }, 500);
  },
  methods: {
    up () {
      let body = $("html, body");
      body.stop().animate({scrollTop:0}, 100, 'swing', function() {});
    }
  },
  beforeDestroy () {
      clearInterval(this.interval);
  }

}
</script>

<style>
  .scroll-up {
      position: fixed;
      right: 15px;
      bottom: 20px;
      z-index: 99999;
      color: white;
      background: #848484;
      border-radius: 4px;
      cursor: pointer;
      width: 40px;
      height: 40px;
  }
  .scroll-up:hover {
    background: #6776f4;
    color: #fff;
  }
</style>
