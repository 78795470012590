<template>
    <div class="col-md-12" v-if="ready">
        <div v-for="(variant, variantId) of totalByPaxVariant">
            <div v-for="(value, pType) of totalByPaxVariant[variantId]">
                <div class="form-row">
                    <div class="col-md-1">
                    </div>
                    <div class="col form-variant ">
                        <input v-model="recordStore.QuoteVariants[baseId][variantId].Selected[pType]" type="checkbox"
                            class="form-check-input" :disabled="!canEdit" @change="setSelected"/>
                        <label>{{recordStore.QuoteVariants[baseId][variantId].Name}} {{pType}}</label>
                    </div>
                    <div class="col">
                        <number-input
                            :addClass="overPrice(variantId, pType)? 'border-warning': ''"
                            :currency="recordStore.Currency"
                            :label="recordStore.CurrencyId"
                            :disabled="!canEdit || !canOverwrite(variantId, pType)"
                            @change="setOverPrice($event, variantId, pType)"
                            :v.sync="value.Price"></number-input>
                    </div>
                    <div class="col" v-if="recordStore.CurrencyId!=recordStore.PriceCurrencyId">
                        <number-input
                            :label="recordStore.PriceCurrencyId"
                            :v="getTotalInCurrency(value.Price)"
                            :currency="recordStore.PriceCurrency"
                            disabled="true"
                        ></number-input>
                    </div>
                    <div class="col-md-5 form-variant form-default ">
                        <div class="form-group form-default my-2">
                            <input v-model="recordStore.QuoteVariants[baseId][variantId].Comments[pType]" class="form-control"
                                :class="{'fill': recordStore.QuoteVariants[baseId][variantId].Comments[pType]}"
                                @change="setComment($event.target.value.Price, variantId, pType)"
                                type="text" :disabled="!canEdit">
                                <span class="form-bar"></span>
                                <label class="float-label ml-2">
                                    {{tr('Comment')}}
                                </label>
                            </input>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'booking-quote-total-variant',
    props: ['quote', 'baseId'],
    data () {
        return {
            ready: false,
            totalByPaxVariant: {},
            sumUpVariants: null,
        }
    },
    mounted () {
        this.setTotals();
        EventBus.$on('update-totals', this.setTotals);
        this.ready = true;
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
        }),
        canEdit () {
            return this.salesActions;
        },
        getPaxTypesByBase () {
            return this.recordStore.getPaxTypesByBase(this.baseId);
        },
    },
    methods: {
        canOverwrite (variantId, pType) {
            return true;
        },
        getTotalInCurrency (price) {
            return this.recordStore.getTotalInCurrency(price);
        },
        setSelected () {
            this.recordStore.forceVariantGetters = !this.recordStore.forceVariantGetters;
            this.recordStore.forceHotelGetters = !this.recordStore.forceHotelGetters;
            this.recordStore.setBookingTotals();
            EventBus.$emit('update-totals');
        },
        setTotals () {
            this.totalByPaxVariant = this.recordStore.totalByPaxVariant[this.baseId];
            //this.sumUpVariants = this.recordStore.sumUpVariants[this.baseId];
        },
        overPrice (variantId, pType) {
            if (this.recordStore.QuoteVariants[this.baseId][variantId].OverPrice) {
                if (this.recordStore.QuoteVariants[this.baseId][variantId].OverPrice[pType]) {
                    return true;
                }
            }
        },
        async setOverPrice (value, variantId, pType) {
            if (!this.recordStore.QuoteVariants[this.baseId][variantId].OverPrice) {
                this.recordStore.QuoteVariants[this.baseId][variantId].OverPrice = {};
            }
            this.recordStore.QuoteVariants[this.baseId][variantId].OverPrice[pType] = value;
            this.setTotals();
            EventBus.$emit('update-totals');
        },
        async setComment (value, variantId, pType) {
            if (!this.recordStore.QuoteVariants[this.baseId][variantId].Comments) {
                this.recordStore.QuoteVariants[this.baseId][variantId].Comments = {};
            }
            this.recordStore.QuoteVariants[this.baseId][variantId].Comments[pType] = value;
        }
    },
    beforeDestroy() {
        EventBus.$off('update-totals', this.setTotals);
    },
}

</script>

