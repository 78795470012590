<script>
const HotelRooms = importVueComp("components/hotel", "HotelRooms", "custom");
export default {
  name: "custom-hotel-rooms",
  mixins: [HotelRooms],
  methods: {
    async setHotel(room, idx) {
      await this.$emit("setHotel", room.HotelId, idx);
      const hotelData = await api.getObjectFromStore("hotel", room.HotelId);
      if (hotelData.Review) alert(hotelData.Review);
    },
  },
};
</script>
