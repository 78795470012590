<template>
    <div id="b-invoice" v-if="ready" @contextmenu="handleClick($event)">
        <div class="button-fixed">
            <button type="button" class="btn btn-primary action-button"
                @click="print()">
                <font-awesome-icon icon="print"/>
            </button>
        </div>
        <div class="container container-body">
            <div class="row mb-5">
                <div class="col-6 row">
                    <div class="col-12">
                        <img width="200" id="logo" src="@/extra/custom/img/logo-full.png" class="">
                    </div>
                </div>
                <div class="col-6 row align-items-center">
                    <div class="col-12">
                        <h2>
                            {{getDocName}}
                        </h2>
                    </div>
                </div>
                <div class="col-6 row">
                    <div class="col-12">
                        Designer Trips Argentina
                    </div>
                    <div class="col-12">
                        Guemes 4747 8th floor suite D CABA (1425) Buenos Aires, Argentina. 
                    </div>
                    <div class="col-12">
                        <a href="mailto:info@designer-trips.com">
                            info@designer-trips.com
                        </a>
                    </div>
                    <div class="col-12">
                        Phone: +54 11 4775 5247
                    </div>
                    <div class="col-12">
                        Emergency Phone: + 54 9 11 3081 8348
                    </div>
                </div>
                <div class="col-6 row">
                    <div class="col-12">
                        {{getDocName}} Number: {{getNumber}}
                    </div>
                    <div class="col-12">
                        Date: {{getDate(new Date())}}
                    </div>
                    <div class="col-12">
                        Id. de cliente: {{booking.PaxName}}
                    </div>
                    <div class="col-12 row mt-3" v-if="booking.Agency">
                        <div class="col-12">
                            <strong>Company:</strong> {{booking.Agency.Name}}
                        </div>
                        <div class="col-12" v-for="row of agencyAddress">
                            {{row}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5 mr-5">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12" v-if="booking.SalesUser">
                            Seller: {{booking.SalesUser.FirstName}} {{booking.SalesUser.LastName}}
                        </div>
                    </div>
                    <hr class="my-2 hr-strong">
                    <div class="row mt-3">
                        <div class="col-2">
                            1
                        </div>
                        <div class="col-8">
                            {{ booking.Title }} - {{ booking.PaxName }}
                        </div>
                        <div class="col-2 text-right">
                            {{amount}}
                        </div>
                    </div>
                    <div class="row mt-5 mb-5">
                    </div>
                    <div class="row mt-5 mb-5">
                    </div>
                    <div class="row mt-5 mb-5">
                    </div>
                    <div class="row mt-3" v-if="showAgencyCommission">
                        <div class="col-3 offset-7">
                            AGENCY COMMISSION {{ recordStore.AgencyCommission }}%
                        </div>
                        <div class="col-2 text-right">
                            {{agencyCommission}}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-3 offset-7">
                            TOTAL TO BE PAID
                        </div>
                        <div class="col-2 text-right">
                            {{amount}}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="col-12">
                    <hr class="my-2 hr-strong">
                    <br><p>Bank: Bank of America Account</p>
                    <p>Number: 898101761939</p>
                    <p>Routing Number Electronic: 063100277</p>
                    <p>Routing Number Cable: 026009593</p>
                    <p>Company Address: 1555 Treasure Dr. Suite 511 City: North Bay Village, Florida 33141</p>
                    <p>Swift Code: BOFAUS3N</p>
                    <p>Bank of America, NA 222 Broadway New York, New York 10038</p><br>
                </div>
            </div>
        </div>
        <footer class="not-print">
            <div class="container">
                <div class="d-flex align-items-center">
                    <div class="col">
                        <p>www.designer-trips.com</p>
                    </div>
                    <div class="col">
                        <img width="170" src="@/extra/custom/img/logo-full.png">
                    </div>
                    <div class="col">
                        <i class="fas fa-envelope"></i> info@designer-trips.com
                    </div>
                </div>
            </div>
        </footer>

        <div class="divFooter">
            <div class="container">
                <div class="d-flex align-items-center">
                    <div class="col">
                        <p>www.designer-trips.com/</p>
                    </div>
                    <div class="col">
                        <img width="170" src="@/extra/custom/img/logo-full.png">
                    </div>
                    <div class="col">
                        <i class="fas fa-envelope"></i> info@designer-trips.com
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
let bookingInvoice = importVueComp('components/documentation', 'BookingInvoice', 'custom');
export default {
    name: 'custom-booking-invoice',
    mixins: [bookingInvoice],
    methods: {
        async getBooking () {
            return await api.get('/public/api/get_booking_prices/' + this.hash + '/' + this.id);
        },
    },
    computed: {
        showAgencyCommission () {
            return (this.recordStore.ShowInvoiceAgencyCommission && this.recordStore.AgencyCommission && this.recordStore.AgencyCommission > 0) ? true : false;
        },
        agencyCommission () {
            if (this.recordStore.agencyCommission){
                return this.booking.PriceCurrencyId + ' ' + this.getTotalInCurrency(this.recordStore.agencyCommission).toFixed(0)
            }
            return 0;
        },
    }
}
</script>