<template>
    <div class="col-6 d-flex align-items-center justify-content-center">
        <h4 v-if="t.Room">
            {{tr(t.pType)}} -  <span v-if="roomTypeNames">{{roomTypeNames}}</span>
            ({{tr('based on')}} {{t.pax}} pax) 
            <br><span v-if="t.Quote.Comment">{{t.Quote.Comment}}</span>
        </h4>
        <h4 v-else>
            {{tr(t.pType)}} 
            ({{tr('based on')}} {{t.pax}} pax) 
            <br><span v-if="t.Quote.Comment">{{t.Quote.Comment}}</span>
        </h4>
    </div>
</template>

<script>
export default {
    name: 'itinerary-prices-name',
    props: ['t', 'roomTypeNames'],
}
</script>


