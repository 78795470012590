<template>
    <div v-if="ready" class="card px-5 py-3 no-border-top">
        <div class="tab-summary">
            <div class="form-row">
                <div class="form-group form-material col-md-1">
                    <select-input
                        v-if="options"
                        fieldName="CurrencyId"
                        :fieldOptions="options"
                        label="Currency"
                        :currentValue.sync="recordStore.SummaryCurrencyId"
                        @change="changeCurrency()"
                    ></select-input>
                </div>
            </div>
            <div class="form-row font-weight-bold">
                <label class="col-5">{{tr('Expenses')}}</label>
                <label class="col-1 text-right">{{tr('Quoted')}}</label>
                <label class="col-1 text-right">{{tr('To Pay')}}</label>
                <label class="col-1 text-right">{{(tr('Paid'))}}</label>
                <!--label class="col-1 text-right">{{tr('Margin')}}</label-->
                <label class="col-1"></label>
            </div>
            <hr class="my-1">
            <div v-for="(supplier, id) of getters.allSuppliers" class="form-row border-bottom" :class="getBGClass(id)">
                <label class="col-5">{{supplier.Name}}</label>
                <label class="col-1 text-right" :style="getColor(id, 'cost')">
                    <span v-if="getters.quoted[id]">{{getters.quoted[id].Cost | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}</span>
                </label>
                <label class="col-1 text-right" :style="getColor(id, 'toPay')">
                    {{getters.totalToPay[id] | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
                <label class="col-1 text-right" :style="getColor(id, 'paid')">
                    {{getters.totalPaid[id] | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
                <!--label class="col-1 text-right" v-if="getters.supplierSummary[id].amount">
                    {{(getMarkup(getters.quoted[id].Price, getters.supplierSummary[id].amount)).toFixed(1)}} %
                </label>
                <label v-else class="col-1 text-right">
                </label-->
                <label class="col-3 text-danger" v-if="getters.paidErrors[id] && getters.paidErrors[id].length>0">
                    {{getErrorText(getters.paidErrors[id])}}
                </label>
            </div>
            <div class="form-row font-weight-bold">
                <label class="col-7">{{tr('Other Expenses')}}</label>
                <label class="col-1 text-right" style="color: #2B8E25">
                    {{getters.totalExpenses | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="form-row font-weight-bold">
                <label class="col-6">{{tr('Total Expenses')}}</label>
                <label class="col-2 text-right">
                    {{getters.finalCost | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="form-row font-weight-bold">
                <label class="col-4">{{tr('Incomes')}}</label>
                <label class="col-2 text-right">{{tr('To Receive')}}</label>
                <label class="col-2 text-right">{{tr('Received')}}</label>
                <label class="col-1"></label>
            </div>
            <hr class="my-1">
            <div v-for="(receipt, id) in getters.bookingReceipts" class="form-row">
                <label class="col-4">{{receipt.PaymentDate | toDate}}</label>
                <label class="col-2 text-right" :style="getColor(id,  'AmountStyle')">
                    {{receipt.Amount | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
                <label class="col-2 text-right" :style="getColor(id, 'RecAmountStyle')">
                    {{receipt.ReceivedAmount | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="form-row font-weight-bold">
                <label class="col-6">{{tr('Total Incomes')}}</label>
                <label class="col-2 text-right">
                    {{getters.finalIncomes | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="spacer"></div>
            <div class="form-row font-weight-bold">
                <label class="col-6">{{tr('File Profit')}}</label>
                <label class="col-1 text-right">{{getters.profitPercent.toFixed(1)}} %</label>
                <label class="col-1 text-right">{{getters.profit | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}</label>
                <div class="col-1 ml-5" v-if="managerActions">
                    <button type="button" class="btn btn-outline-secondary close-booking-button"
                        @click="closeBooking">
                        {{tr('Close File')}}
                    </button>
                </div>
            </div>
            <hr class="my-1">

            <div class="spacer" v-if="recordStore.ProfitSummary && Object.keys(recordStore.ProfitSummary).length>0">
                <b>
                <div class="form-row bold-row">
                    <label class="col-3 border-bottom">{{tr('Summary')}}</label>
                </div>
                <div class="form-row bold-row" v-if="recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Cost">
                    <label class="col-1">{{tr('Outcomes')}}</label>
                    <label class="col-1 text-right">{{recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Cost | formatNumber({c: recordStore.SummaryCurrency})}}</label>
                    <label class="col-1">{{recordStore.SummaryCurrencyId}}</label>
                </div>
                <div class="form-row bold-row" v-if="recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Incomes">
                    <label class="col-1">{{tr('Incomes')}}</label>
                    <label class="col-1 text-right">{{recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Incomes | formatNumber({c: recordStore.SummaryCurrency})}}</label>
                    <label class="col-1">{{recordStore.SummaryCurrencyId}}</label>
                </div>
                <div class="form-row bold-row" v-if="recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Profit">
                    <label class="col-1 border-bottom">{{tr('Profit')}}</label>
                    <label class="col-1 text-right border-bottom">{{recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Profit | formatNumber({c: recordStore.SummaryCurrency})}}</label>
                    <label class="col-1 border-bottom">{{recordStore.SummaryCurrencyId}}</label>
                </div>
                </b>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";
export default {
    name: 'booking-summary',
    data () {
        return {
            lastPurchaseCurrencyRate: null,
            ready: false,
            getters: {
                totalExpenses: null,
                allSuppliers: null,
                quoted: null,
                totalToPay: null,
                totalPaid: null,
                supplierSummary: null,
                finalCost: null,
                profitPercent: null,
                finalIncomes: null,
                profit: null,
                bookingReceipts: null,
                paidErrors: null,
            }
        };
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            managerActions: state => state.main.managerActions,
            recordStore: state => state.main.record,
        }),
        baseCurrency () {
            return api.baseCurrency;
        },
        options () {
            let res = [];
            res.push({label: this.baseCurrency.BookingCurrency, value: this.baseCurrency.BookingCurrency});
            res.push({label: this.baseCurrency.BaseCurrency, value: this.baseCurrency.BaseCurrency});
            return res;
        },
        colorsList () {
            let res = {suppliers: {}, receipts: {}};
            for (let id in this.getters.allSuppliers) {
                if (!this.getters.supplierSummary[id]) continue;
                    res.suppliers[id] = {cost: '', toPay: '', paid: ''};
                if (this.getters.supplierSummary[id].options)  {
                    for (let k in this.getters.supplierSummary[id].options) {
                        res.suppliers[id][k] = this.getters.supplierSummary[id].options[k]? 'color: #2B8E25': '';
                    }
                }
            }
            for (let id in this.getters.bookingReceipts) {
                let r = this.getters.bookingReceipts[id];
                res.receipts[r.id] = {AmountStyle: '', RecAmountStyle: ''};
                if (r.Amount) {
                    res.receipts[r.id].AmountStyle = 'color: #2B8E25';
                    res.receipts[r.id].RecAmountStyle = '';
                }
                if (r.ReceivedAmount) {
                    res.receipts[r.id].RecAmountStyle = 'color: #2B8E25';
                    res.receipts[r.id].AmountStyle = '';
                }
            }

            return res;
        },
    },
    async mounted () {
        await this.onMount();
    },
    methods: {
        async onMount () {
            this.recordStore.SummaryCurrencyId = null;
            if (this.baseCurrency.BookingCurrency) {
                this.recordStore.SummaryCurrencyId = this.baseCurrency.BookingCurrency;
                if (this.baseCurrency.SummaryCurrency) {
                    this.recordStore.SummaryCurrencyId = this.baseCurrency.SummaryCurrency;
                }
                //await this.changeCurrency();
            }
            this.setGetters();
            this.ready = true;
        },
        getMarkup (price, cost) {
            return tools.getMarkup(price, cost);
        },
        setGetters () {
            api.setProcessing(true);
            for (let id in this.getters) {
                this.getters[id] = this.recordStore[id];
            }
            api.setProcessing(false);
        },
        async changeCurrency () {
            this.recordStore.setValue({fieldName: 'SummaryCurrencyId', value: this.recordStore.SummaryCurrencyId});
            this.setGetters();
        },
        closeBooking () {
            this.recordStore.closeFile(this.baseCurrency);
        },
        getColor (id, type) {
            let t = 'receipts';
            if (type == 'toPay' || type == 'cost' || type == 'paid') {
                t = 'suppliers';
            }
            if (this.colorsList[t][id] && this.colorsList[t][id][type]) {
                return this.colorsList[t][id][type];
            }
            return '';
        },
        getBGClass (id) {
            return '';
            if (id % 2 == 0) return 'bg-line';
            return '';
        },
        getErrorText (errors) {
            return _.map(errors, (f) => {
                return _.map(f, (r) => {
                    return tr(r)
                }).join(' ');
            }).join('; ');
        }
    },
};
</script>
